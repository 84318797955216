/**
 * Site header
 */

$header-height: 128px;

.site-header {
    background-color: $header-color;
    min-height: $header-height;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {

    line-height: 1;
    font-size: 1.8rem;
    width: 10rem;
    font-family: $head-font-family;
    text-align: center;
    padding: $spacing-unit 0;
    float: right;

    &:first-line {
        font-size: 3rem;
    }

    &,
    &:visited {
        color: $header-fg-color;
    }

    &:hover {
        text-decoration: none;
    }
}

.site-nav {
    float: left;
    line-height: $header-height;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $header-fg-color;
        line-height: $base-line-height;
        font-family: $head-font-family;
        font-size: 2rem;

        &:hover {
            text-decoration-style: dotted;
            text-decoration-line: underline;
        }

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {

        position: absolute;
        top: $spacing-unit;
        left: $spacing-unit;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: left;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            color: $text-color;
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin: 0px 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    color: $footer-fg-color;
    background-color: $footer-color;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;

    a {
        color: $light-text-color;
    }

    span.fa {
        width: 24px;
        text-align: center;
    }
}

.footer-col-wrapper {
    font-size: 15px;
    margin-left: calc(-1 * $spacing-unit / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-latest-posts {
    list-style: none;
    a,
    a:hover,
    a:visited {
        color: inherit;
    }
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    width: 100%;
}

.page-heading {
    font-size: 28px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

.pagination {
    margin-bottom: 15px;

    .page-number {
        display: inline-block;
        width: 100px;
        text-align: center;
    }
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

/**
 * Custom elements
 */
.sidebar-banner {
    @extend %clearfix;
    background-color: $accent-color;
    color: $light-text-color;
    max-width: $content-width - $spacing-unit*4;
    margin: 0 auto;
    padding: $spacing-unit*2 $spacing-unit;

    p {
        clear: both;
    }
}

.float-up {
    margin-top: -150px;
}

.float-down {
    margin-top: 100px;
}

.headshot {
    width: 80%;
    max-width: 15em;
}

.big-text {
    font-size: 120%;
    display: block;
}

.home {
    clear: both;
    padding-top: $spacing-unit;
}

@include media-query-min($on-palm) {
    .right-wrapper {
        max-width: $content-width;
        float: right;
        max-width: 60%;
    }

    .headshot {
        width: 30%;

    }
}
