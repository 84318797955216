/* Generated by Font Squirrel (https://www.fontsquirrel.com) */
$fonts-dir: '../fonts/';

@font-face {
    font-family: 'Neuton';
    src: url($fonts-dir + 'neuton-regular-webfont.eot');
    src: url($fonts-dir + 'neuton-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url($fonts-dir + 'neuton-regular-webfont.woff2') format('woff2'),
         url($fonts-dir + 'neuton-regular-webfont.woff') format('woff'),
         url($fonts-dir + 'neuton-regular-webfont.ttf') format('truetype'),
         url($fonts-dir + 'neuton-regular-webfont.svg#neutonregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url($fonts-dir + 'sourcesanspro-regular-webfont.eot');
    src: url($fonts-dir + 'sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url($fonts-dir + 'sourcesanspro-regular-webfont.woff2') format('woff2'),
         url($fonts-dir + 'sourcesanspro-regular-webfont.woff') format('woff'),
         url($fonts-dir + 'sourcesanspro-regular-webfont.ttf') format('truetype'),
         url($fonts-dir + 'sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
