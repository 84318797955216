@charset "utf-8";



// Our variables
$base-font-family: "Source Sans Pro", sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$head-font-family: "Neuton", serif;
$head-font-weight: 400;

$spacing-unit:     30px;

$text-color:       #333;
$light-text-color: #fff;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$header-color:     #05a8d3;
$header-fg-color:  $light-text-color;

$footer-color:     #39b86e;
$footer-fg-color:  $light-text-color;

$accent-color:     #660a31;
$accent-fg-color:  $light-text-color;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin media-query-min($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "fonts",
        "base",
        "layout",
        "syntax-highlighting"
;
